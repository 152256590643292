* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.App {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

.MainPage {
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: auto;
  width: 100vw;
  height: 100vh;
}

.MainPage > .info {
  order: 1;
}
.MainPage > .main-map {
  order: 2;
}

.MainPage .expand-info {
  display: none;
}

.MainPage .geo-info {
  padding: 5px;
}

@media only screen and (max-width: 500px) {
  .MainPage {
    display: inline-block;
    width: 100vw;
    height: 100vh;
  }

  .MainPage > .info {
    z-index: 1;
    background: white;
    position: fixed;
    width: 100%;
    bottom: 0px;
  }

  .MainPage > .info-collapsed {
    height: 100px;
  }

  .MainPage > .info-expanded {
    height: 85%;
  }

  .MainPage > .main-map {
    position: fixed;
    top: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
  }

  .MainPage .expand-info {
    position: absolute;
    margin: 5px;
    top: 0;
    right: 0;
    z-index: 2;
    display: inline-block;
  }
}

.MainPage > div {
  overflow: hidden;
  min-width: 0;
}
